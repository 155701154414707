import { createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-afa84ab3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tab-header"
}
const _hoisted_2 = ["title", "data-is-user-generated", "onClick", "onDragover"]
const _hoisted_3 = ["hidden"]
const _hoisted_4 = ["title"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 1,
  class: "add-btn-text"
}
const _hoisted_7 = ["title"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "tab-contents" }
const _hoisted_10 = ["hidden"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!($setup.store.form.layout.tabs.length == 1 && $setup.store.read_only))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _withDirectives(_createVNode($setup["draggable"], {
            class: "tabs",
            modelValue: $setup.store.form.layout.tabs,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.store.form.layout.tabs) = $event)),
            group: "tabs",
            animation: 200,
            easing: $setup.store.get_animation,
            "item-key": "id",
            disabled: $setup.store.read_only
          }, {
            item: _withCtx(({ element }) => [
              _createElementVNode("div", {
                class: _normalizeClass(['tab', $setup.store.form.active_tab == element.df.name ? 'active' : '']),
                title: element.df.fieldname,
                "data-is-user-generated": $setup.store.is_user_generated_field(element),
                onClick: _withModifiers($event => ($setup.activate_tab(element)), ["stop"]),
                onDragstart: _cache[0] || (_cache[0] = $event => ($setup.dragged = true)),
                onDragend: _cache[1] || (_cache[1] = $event => ($setup.dragged = false)),
                onDragover: $event => ($setup.drag_over(element))
              }, [
                _createVNode($setup["EditableInput"], {
                  text: element.df.label,
                  placeholder: _ctx.__('Tab Label'),
                  modelValue: element.df.label,
                  "onUpdate:modelValue": $event => ((element.df.label) = $event)
                }, null, 8 /* PROPS */, ["text", "placeholder", "modelValue", "onUpdate:modelValue"])
              ], 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_2)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "easing", "disabled"]), [
            [_vShow, $setup.has_tabs]
          ]),
          _createElementVNode("div", {
            class: "tab-actions",
            hidden: $setup.store.read_only
          }, [
            _createElementVNode("button", {
              class: _normalizeClass(["new-tab-btn btn btn-xs", { 'no-tabs': !$setup.has_tabs }]),
              title: _ctx.__('Add new tab'),
              onClick: $setup.add_new_tab
            }, [
              ($setup.has_tabs)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    innerHTML: _ctx.frappe.utils.icon('add', 'sm')
                  }, null, 8 /* PROPS */, _hoisted_5))
                : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.__("Add new tab")), 1 /* TEXT */))
            ], 10 /* CLASS, PROPS */, _hoisted_4),
            ($setup.has_tabs)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "remove-tab-btn btn btn-xs",
                  title: _ctx.__('Remove selected tab'),
                  onClick: $setup.remove_tab
                }, [
                  _createElementVNode("div", {
                    innerHTML: _ctx.frappe.utils.icon('remove', 'sm')
                  }, null, 8 /* PROPS */, _hoisted_8)
                ], 8 /* PROPS */, _hoisted_7))
              : _createCommentVNode("v-if", true)
          ], 8 /* PROPS */, _hoisted_3)
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_9, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.store.form.layout.tabs, (tab, i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["tab-content", [$setup.store.form.active_tab == tab.df.name ? 'active' : '']]),
          key: i
        }, [
          _createVNode($setup["draggable"], {
            class: "tab-content-container",
            modelValue: tab.sections,
            "onUpdate:modelValue": $event => ((tab.sections) = $event),
            group: "sections",
            animation: 200,
            easing: $setup.store.get_animation,
            "item-key": "id",
            disabled: $setup.store.read_only
          }, {
            item: _withCtx(({ element }) => [
              _createVNode($setup["Section"], {
                tab: tab,
                section: element,
                "data-is-user-generated": $setup.store.is_user_generated_field(element)
              }, null, 8 /* PROPS */, ["tab", "section", "data-is-user-generated"])
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue", "easing", "disabled"]),
          _createElementVNode("div", {
            class: "empty-tab",
            hidden: $setup.store.read_only
          }, [
            _createElementVNode("div", null, _toDisplayString(_ctx.__("Drag & Drop a section here from another tab")), 1 /* TEXT */),
            _createElementVNode("div", null, _toDisplayString(_ctx.__("OR")), 1 /* TEXT */),
            _createElementVNode("button", {
              class: "btn btn-default btn-sm",
              onClick: $setup.add_new_section
            }, _toDisplayString(_ctx.__("Add a new section")), 1 /* TEXT */)
          ], 8 /* PROPS */, _hoisted_10)
        ], 2 /* CLASS */))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ], 64 /* STABLE_FRAGMENT */))
}