import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31db23b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = ["hidden"]
const _hoisted_3 = { class: "column-label" }
const _hoisted_4 = { class: "column-actions" }
const _hoisted_5 = ["title"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["title"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["title", "onClick"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  key: 0,
  class: "column-description"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
			'column',
			$setup.hovered ? 'hovered' : '',
			$setup.store.selected($props.column.df.name) ? 'selected' : ''
		]),
    title: $props.column.df.fieldname,
    onClick: _cache[2] || (_cache[2] = _withModifiers($event => ($setup.store.form.selected_field = $props.column.df), ["stop"])),
    onMouseover: _cache[3] || (_cache[3] = _withModifiers($event => ($setup.hovered = true), ["stop"])),
    onMouseout: _cache[4] || (_cache[4] = _withModifiers($event => ($setup.hovered = false), ["stop"]))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([
				'column-header',
				$props.column.df.label ? 'has-label' : '',
			]),
      hidden: !$props.column.df.label && $setup.store.read_only
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["EditableInput"], {
          text: $props.column.df.label,
          placeholder: _ctx.__('Column Title'),
          modelValue: $props.column.df.label,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($props.column.df.label) = $event))
        }, null, 8 /* PROPS */, ["text", "placeholder", "modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        ($props.section.columns.indexOf($props.column))
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-xs btn-icon",
              title: _ctx.__('Move the current column & the following columns to a new section'),
              onClick: $setup.move_columns_to_section
            }, [
              _createElementVNode("div", {
                innerHTML: _ctx.frappe.utils.icon('move', 'sm')
              }, null, 8 /* PROPS */, _hoisted_6)
            ], 8 /* PROPS */, _hoisted_5))
          : _createCommentVNode("v-if", true),
        _createElementVNode("button", {
          class: "btn btn-xs btn-icon",
          title: _ctx.__('Add Column'),
          onClick: $setup.add_column
        }, [
          _createElementVNode("div", {
            innerHTML: _ctx.frappe.utils.icon('add', 'sm')
          }, null, 8 /* PROPS */, _hoisted_8)
        ], 8 /* PROPS */, _hoisted_7),
        _createElementVNode("button", {
          class: "btn btn-xs btn-icon",
          title: _ctx.__('Remove Column'),
          onClick: _withModifiers($setup.remove_column, ["stop"])
        }, [
          _createElementVNode("div", {
            innerHTML: _ctx.frappe.utils.icon('remove', 'sm')
          }, null, 8 /* PROPS */, _hoisted_10)
        ], 8 /* PROPS */, _hoisted_9)
      ])
    ], 10 /* CLASS, PROPS */, _hoisted_2),
    ($props.column.df.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString($props.column.df.description), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["draggable"], {
      class: "column-container",
      style: _normalizeStyle({ backgroundColor: $props.column.fields.length ? '' : 'var(--field-placeholder-color)' }),
      modelValue: $props.column.fields,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($props.column.fields) = $event)),
      group: "fields",
      animation: 200,
      easing: $setup.store.get_animation,
      "item-key": "id",
      disabled: $setup.store.read_only
    }, {
      item: _withCtx(({ element }) => [
        _createVNode($setup["Field"], {
          column: $props.column,
          field: element,
          "data-is-user-generated": $setup.store.is_user_generated_field(element)
        }, null, 8 /* PROPS */, ["column", "field", "data-is-user-generated"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["style", "modelValue", "easing", "disabled"])
  ], 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_1))
}