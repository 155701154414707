import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44154705"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "control-data" }
const _hoisted_2 = { key: 0 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["SearchBox"], {
      modelValue: $setup.search_text,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.search_text) = $event))
    }, null, 8 /* PROPS */, ["modelValue"]),
    _createElementVNode("div", _hoisted_1, [
      ($setup.store.form.selected_field)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.docfield_df, (df, i) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "field",
                key: i
              }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(df.fieldtype.replace(' ', '') + 'Control'), {
                  args: $setup.args,
                  df: df,
                  read_only: $setup.store.read_only,
                  value: $setup.store.form.selected_field[df.fieldname],
                  modelValue: $setup.store.form.selected_field[df.fieldname],
                  "onUpdate:modelValue": $event => (($setup.store.form.selected_field[df.fieldname]) = $event),
                  "data-fieldname": df.fieldname,
                  "data-fieldtype": df.fieldtype
                }, null, 8 /* PROPS */, ["args", "df", "read_only", "value", "modelValue", "onUpdate:modelValue", "data-fieldname", "data-fieldtype"]))
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ], 64 /* STABLE_FRAGMENT */))
}