import { createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-83aa7031"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-sidebar" }
const _hoisted_2 = { class: "form-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.should_render)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref: "container",
        class: "form-builder-container",
        onClick: _cache[1] || (_cache[1] = $event => ($setup.store.form.selected_field = null))
      }, [
        _createElementVNode("div", {
          class: "form-controls",
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode($setup["Sidebar"])
          ])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(["form-main", [$setup.store.preview ? 'preview' : '']])
          }, [
            _createVNode($setup["Tabs"])
          ], 2 /* CLASS */)
        ])
      ], 512 /* NEED_PATCH */))
    : _createCommentVNode("v-if", true)
}