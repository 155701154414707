import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-be90f500"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tab-header" }
const _hoisted_2 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(['sidebar-resizer', $setup.sidebar_resizing ? 'resizing' : '']),
      onMousedown: $setup.start_resize
    }, null, 34 /* CLASS, HYDRATE_EVENTS */),
    _createElementVNode("div", {
      class: "sidebar-container",
      style: _normalizeStyle({ width: `${$setup.sidebar_width}px` })
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tab_titles, (tab, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(['tab', $setup.active_tab == tab ? 'active' : '']),
            key: i,
            onClick: $event => ($setup.active_tab = tab)
          }, _toDisplayString(tab), 11 /* TEXT, CLASS, PROPS */, _hoisted_2))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(['tab-content', $setup.active_tab == $setup.tab_titles[0] ? 'active' : ''])
      }, [
        _createVNode($setup["FieldTypes"])
      ], 2 /* CLASS */),
      _createElementVNode("div", {
        class: _normalizeClass(['tab-content', $setup.active_tab == $setup.tab_titles[1] ? 'active' : ''])
      }, [
        _createVNode($setup["FieldProperties"])
      ], 2 /* CLASS */)
    ], 4 /* STYLE */)
  ], 64 /* STABLE_FRAGMENT */))
}