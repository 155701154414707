import { withKeys as _withKeys, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a015357f"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = ["disabled", "placeholder", "value"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 2,
  class: "text-muted"
}
const _hoisted_5 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    onDblclick: $setup.focus_on_label,
    title: _ctx.__('Double click to edit label')
  }, [
    ($setup.editing)
      ? (_openBlock(), _createElementBlock("input", {
          key: 0,
          class: "input-text",
          ref: "input_text",
          disabled: $setup.store.read_only,
          type: "text",
          placeholder: $props.placeholder,
          value: $props.text,
          style: _normalizeStyle({ width: $setup.hidden_span_width }),
          onInput: _cache[0] || (_cache[0] = event => _ctx.$emit('update:modelValue', event.target.value)),
          onKeydown: _cache[1] || (_cache[1] = _withKeys($event => ($setup.editing = false), ["enter"])),
          onBlur: _cache[2] || (_cache[2] = $event => ($setup.editing = false)),
          onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
        }, null, 44 /* STYLE, PROPS, HYDRATE_EVENTS */, _hoisted_2))
      : ($props.text)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            innerHTML: $props.text
          }, null, 8 /* PROPS */, _hoisted_3))
        : (_openBlock(), _createElementBlock("i", _hoisted_4, _toDisplayString($props.empty_label), 1 /* TEXT */)),
    _createElementVNode("span", {
      class: "hidden-span",
      ref: "hidden_text",
      innerHTML: $props.text
    }, null, 8 /* PROPS */, _hoisted_5),
    _createElementVNode("span", {
      class: "hidden-span",
      ref: "hidden_placeholder"
    }, _toDisplayString($props.placeholder), 513 /* TEXT, NEED_PATCH */)
  ], 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_1))
}