import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9db04496"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["SearchBox"], {
      modelValue: $setup.search_text,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.search_text) = $event))
    }, null, 8 /* PROPS */, ["modelValue"]),
    _createVNode($setup["draggable"], {
      class: "fields-container",
      list: $setup.fields,
      group: { name: 'fields', pull: 'clone', put: false },
      sort: false,
      clone: $setup.clone_field,
      "item-key": "id",
      "remove-clone-on-hide": false,
      onStart: $setup.on_drag_start,
      onEnd: $setup.on_drag_end
    }, {
      item: _withCtx(({ element }) => [
        _createElementVNode("div", {
          class: "field",
          title: element.df.fieldtype
        }, _toDisplayString(element.df.fieldtype), 9 /* TEXT, PROPS */, _hoisted_1)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["list", "clone"])
  ], 64 /* STABLE_FRAGMENT */))
}